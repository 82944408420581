import { createRouter, createWebHistory } from 'vue-router'
import AppHome from './views/AppHome.vue'

export const router = createRouter({ 
  history: createWebHistory(),
  

  base: process.env.BASE_URL,

  //scrollBehavior: () => ({ top: 0 }),
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x:0, y:0 }
  },

  routes: [
    {
      path: '/missions/agage/',
      name: 'Home',
      displayName: 'Home',
      showOnMenu: true,
      component: AppHome,  // load on import
    },
    {
      path: '/missions/agage/about',
      name: 'About',
      displayName: 'About',
      showOnMenu: true,
      component: () => import('./views/AppAbout.vue'),  // lazy load
      children: [
        {
          path: '',
          name: 'About Us',
          displayName: 'About Us',
          showOnMenu: true,
          component: () => import('./components/about/AboutUs'),  // lazy load
        },
        {
          path: 'agage-team',
          name: 'AGAGE Team',
          displayName: 'AGAGE Team',
          showOnMenu: true,
          component: () => import('./components/about/Team'),  // lazy load
        },
        {
          path: 'past-members',
          name: 'Past Members',
          displayName: 'Past Members',
          showOnMenu: true,
          component: () => import('./components/about/PastMembers'),  // lazy load
        },
        {
          path: 'participating-institutions',
          name: 'Participating Institutions',
          displayName: 'Participating Institutions',
          showOnMenu: true,
          component: () => import('./components/about/ParticipatingInstitutions'),  // lazy load
        },
      ]
    },   
    {
      path: '/missions/agage/data',
      name: 'Data & Figures',
      displayName: 'Data & Figures',
      showOnMenu: true,
      component: () => import('./views/AppData.vue'),  // lazy load
      children: [
        {
          path: '',
          name: 'Data & Figures',
          displayName: 'Data & Figures',
          showOnMenu: true,
          component: () => import('./components/data/Data.vue'),  // lazy load
        },
        {
          path: 'policy',
          name: 'Policy',
          displayName: 'Data Policy',
          showOnMenu: true,
          component: () => import('./components/data/Policy.vue'),  // lazy load
        },
      ]
    }, 
    {
      path: '/missions/agage/global-network',
      name: 'Global Network',
      displayName: 'Global Network',
      showOnMenu: true,
      component: () => import('./views/AppGlobalNetwork.vue'),  // lazy load
    }, 
    {
      path: '/missions/agage/instruments',
      name: 'InstrumentsMenu',
      displayName: 'Instruments',
      showOnMenu: true,
      component: () => import('./views/AppInstruments.vue'),  // lazy load
      children: [
        {
          path: '',
          name: 'Instruments',
          displayName: 'Instruments',
          showOnMenu: true,
          component: () => import('./components/instruments/Instruments'),  // lazy load
        },
        {
          path: 'gas-chromatography-multidetector-gc-md',
          name: 'Gas Chromatography - MultiDetector (GC-MD)',
          displayName: 'Gas Chromatography - MultiDetector (GC-MD)',
          showOnMenu: true,
          component: () => import('./components/instruments/GCMD'),  // lazy load
        },
        {
          path: 'gas-chromatography-mass-spectrometry-ads-gc-ms',
          name: 'Gas Chromatography with Mass Spectrometry (ADS GC-MS)',
          displayName: 'Gas Chromatography with Mass Spectrometry (ADS GC-MS)',
          showOnMenu: true,
          component: () => import('./components/instruments/GCMS'),  // lazy load
        },
        {
          path: 'medusa-gas-chromatography-mass-spectrometry-medusa-gc-ms',
          name: 'MEDUSA Gas Chromatography with Mass Spectrometry (Medusa GC-MS)',
          displayName: 'MEDUSA Gas Chromatography with Mass Spectrometry (Medusa GC-MS)',
          showOnMenu: true,
          component: () => import('./components/instruments/Medusa'),  // lazy load
        },
      ]
    },  
    {
      path: '/missions/agage/stations/:station_directory',
      name: 'Station',
      showOnMenu: false,
      component: () => import('./views/AppStation.vue'),  // lazy load
    },
    {
      path: '/missions/agage/research',
      name: 'Research',
      displayName: 'Research',
      showOnMenu: true,
      component: () => import('./views/AppResearch.vue'),  // lazy load
      children: [
        {
          path: 'publications',
          name: 'Publications',
          displayName: 'Publications',
          showOnMenu: true,
          component: () => import('./components/research/PublicationsTopPage.vue'),  // lazy load
        },
        {
          path: 'highlights',
          name: 'Highlights',
          displayName: 'Highlights',
          showOnMenu: true,
          component: () => import('./components/research/Highlights.vue'),  // lazy load
        },
        {
          path: 'publication/:publication_url',
          name: 'Publication',
          showOnMenu: false,
          component: () => import('./components/research/Publication.vue'),  // lazy load
        },
        {
          path: 'research/:research_url',
          name: 'Research Info',
          showOnMenu: false,
          component: () => import('./components/research/Research.vue'),  // lazy load
        },
      ]
    },
    {
      path: '/missions/agage/sitemap',
      name: 'Site Map',
      showOnMenu: false,
      component: () => import('./views/AppSiteMap.vue'),  // lazy load
    },
    {
      path: '/missions/agage/404',
      name: '404',
      showOnMenu: false,
      component: () => import('./views/AppNotFound.vue'),  // lazy load
    },
    {
      path: '/missions/agage/:pathMatch(.*)*',
      redirect: '/missions/agage/404',
      beforeEnter() { window.location.href = "/missions/agage/404" },
    },

    // {
    //   path: '/calendar',
    //   name: 'Calendar',
    //   displayName: 'Data Calendar',
    //   component: () => import('./views/AppGraph.vue'),  // lazy load
    // },
    // {
    //   path: '/download',
    //   name: 'Download',
    //   displayName: 'Download',
    //   component: () => import('./views/AppDownload.vue'),  // lazy load
    // },
    // {
    //   path: 'TOLNET-497/publications',
    //   name: 'Publications',
    //   displayName: 'Publications',
    //   component: () => import('./views/AppPublications.vue'),  // lazy load
    // },
    // {
    //   path: '/team-area',
    //   displayName: 'Team Area',
    //   component: () => import('./views/AppTeamArea.vue'),  // lazy load
    //   children: [
    //     // Note we provide the above parent route name on the default child tab
    //     // route to ensure this tab is rendered by default when using named routes
    //     { path: '', component: () => import('./components/team-area/TeamDocuments'),  name: 'Team Area' },
    //     { path: 'upload', component: () => import('./components/team-area/UploadData'), name: 'upload-data'  },
    //     { path: 'meetings', component: () => import('./components/team-area/TeamMeetings'), name: 'team-meetings'  }
    //   ]
    // },
    // {
    //   path: '/admin-area',
    //   name: 'Admin',
    //   displayName: 'Admin',
    //   component: () => import('./views/AppAdminArea.vue'),  // lazy load
    // },
    // {
    //   path: 'TOLNET-497/contact-us',
    //   name: 'Contact Us',
    //   displayName: 'Contact Us',
    //   component: () => import('./views/AppContactUs.vue'),  // lazy load
    // },

  ]
})

// router.resolve({
//   name: 'not-found',
//   params: { pathMatch: ['not', 'found'] },
//   // TODO: make 404 page
// }).href

//TODO: set timer to alert user prior to expiration and implement auto-logout

// if the user is not logged-in, do not allow deep linking: redirect to /;
// else, allow passage; store.user is not reliable at this stage or beforeResolve

// router.beforeEach( (to,from,next) => {
//   if ( ! to.loginRequired ) next()
//   else if ( ! to.name || ! to.children) next('/')
//   else api.get(consts.URLS.ident)  // validate w/ server as guard
//           .then( response => next() )
//           .catch( error => next('/') )
//   })
